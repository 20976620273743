import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject } from '../../../components/Intro/Intro';
import { StyledLink } from '../../../components/StyledLink';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { CardContainer, CardBody } from '../../../components/Card';
import { Box, ProjectBox } from '../../../components/Box';
import { Text } from '../../../components/Text';
import { IconBox, IconCard } from '../../../components/IconBox';
import { designTokens } from '../../../components/Theme/designTokens';
import { CallToAction } from '../../../components/CallToAction';
import { ButtonOutbound } from '../../../components/Button';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdHammer from 'react-ionicons/lib/MdHammer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Race Times" mdxType="SEO" />
    <IntroProject projectName="Race Times" projectDescription="Tracking the local race times of the race calendar" projectYear="2019" projectImage="race-times-preview" mdxType="IntroProject" />
    <Section name="Project" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Overview</h5>
        <IconCard cardTitle="Specifications" cardText="A small, personal side project to try and create the easiest way to track Formula 1 races per my local time zone." mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
        </IconCard>
        <IconCard cardTitle="Tools" cardText="Figma, CodePen, Atom, GitHub, HTML, SCSS, Javascript, Notion, Google Forms, Ergast Formula 1 API" mdxType="IconCard">
          <MdHammer fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdHammer" />
        </IconCard>
        <p>Millions of people, from a variety of time zones, tune in to watch the next Grand Prix every other Sunday (or Saturday night, depending on the time zone). But scheduling a fixed time to watch on race day is difficult due to:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <ul>
            <li>Different race locations for each Grand Prix</li>
            <li>Worldwide viewership</li>
            <li>Varied race time in the race location</li>
            <li>Difficulty in finding the race time translated per the user's time zone</li>
            <li>Unlike a national sport, such as the NFL, set times are a bit more difficult to schedule for Formula 1.</li>
          </ul>
        </Box>
        <p>In order for a viewer to gauge when the next race will be, they either have to dig past multiple clicks on the official Formula 1 website/app, or utilize Google Search for realtime updates on the searched race.</p>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">Problem:</Text>
            <p className="lead">How might we give Formula 1 race fans the ability to quickly discern the time of the a race in their time zone?</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAACTklEQVQ4y32Ty2/aQBDG/ef20lOlRqrUSIEeUkVRQloQidq0l+TEIyQBzKOHVlWk9tgUIqG62OFhDIoxYLANlK87ixZtLLVIo/Wa8W+/mW9WmbhTiBhPXB4zz0dTN5C7zuNtPIFoNIqdnQgOYzGkMxlo2m+eQ7niu6Ez4qsiICJG4wnmiyXK1Sq079/wpVTC8ftTnH74iK83N6jV75DLXWKx/MNzZTHOaLwGyi8pyQ/mqFY/QavXEUwmwGoF8et2Ori8uobnB7CHDocIMcRQ5FI3wPkC5WIRv97F4cT3MHyzC3s/gvGrLWiRLaYwh4BVQTDKp1WEIm+oD2uFC6iqCm0vgofX27B2X8J68RQPz5+gsf0MmUyWA+WShbBND93pbAOmktVKBffqFbzPFUxHI8xcFwEzwmBmZS8ueJ8JGPZAkelCYcBKLpXLMA0dPV2Hbhi4b7XYoVP0BwMOFKbI/eM9FO4ImACqagmm1YftODBNE57ncVPMrolMNvtvlwVI/CGARdbDdruNbreLZrPJw2Fw2lMPCSj3XxikhIdazGGxqHJltm2j1+vB932ukJ5T6TTvM42NKPtRyfLE00k0Y/l8gSnsMEUmGo0Gvx2WZaHFVBNwHnJ5Y0pYNp1Kp+cLBa5wyPYdNsykcMUGnEomYNiURy7LKsVNWQN76PcHMJjLOhsXj0FJZSqV/r/LImSF5GStVuMwWkmZy2axzq7j2dk5luw2imsnDzd3WXZJKPxZqyN2dIR4IoFkMonjkxMk2PPBwSF+3N7ynLAhFH8BEYRLF9MD0mkAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Race Times preview",
          "title": "Race Times preview",
          "src": "/static/3483f5acbd1c95f621e74cacbe72f067/d9199/preview-1.png",
          "srcSet": ["/static/3483f5acbd1c95f621e74cacbe72f067/8ff5a/preview-1.png 240w", "/static/3483f5acbd1c95f621e74cacbe72f067/e85cb/preview-1.png 480w", "/static/3483f5acbd1c95f621e74cacbe72f067/d9199/preview-1.png 960w", "/static/3483f5acbd1c95f621e74cacbe72f067/c1b63/preview-1.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <Section name="Goals" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Scenario Goals</h5>
        <p>Now that I had nailed down some of the MVP features of this tool, I needed to start putting pen to paper and ideating upon the scenario goals. While there is no real or current business need, I could still utilize the Lean UX method to wireframe, test, critique, and prototype this idea. I wanted to have each goal of this app to be able to:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <ul>
            <li>Empathize - understand the target audience</li>
            <li>Ideate - sketching and sharing my ideas often</li>
            <li>Design - organize the loose ideas from step 1 and 2 and get user feedback.</li>
          </ul>
        </Box>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Goal #1</Text>
            <Text mdxType="Text">I want to quickly see what time the next Formula 1 race starts per my time zone.</Text>
          </CardBody>
        </CardContainer>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Goal #2</Text>
            <Text mdxType="Text">I want to see which Formula 1 races are left in the current season, as well as which have ended.</Text>
          </CardBody>
        </CardContainer>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <Text bold color={({
                    theme
                  }) => theme.green} mdxType="Text">Goal #3</Text>
            <Text mdxType="Text">I want to see simple historical data (circuits, race locations, etc) on past Formula 1 races.</Text>
          </CardBody>
        </CardContainer>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <Section name="Wireframes" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Initial Wireframes</h5>
        <p>To not waste too much time, I crafted a few simple wireframe layouts that would help map the user’s path of inquiry. After a quick round of initial research, I found that users simply wanted to see the time and date of the next upcoming race. Tests that ran well showed the race calendar inside of a list view rather than multiple cards in a single row. A simple tab component was included to help filter the selected season's races and generate the update within the list view.</p>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAACOElEQVQ4y3VT2W7bMBDk/39TCwc90KBBH5OH9KW26kPRRdk6KEridmYlCo3RCl5T4nJmZw+apu38v+zWtLq2Xa/v9/Y/nAFA/raud9K0vQzeixsG7HUSggh+WPkvMgxez7Q4e483YA2I+M6utyYMzoXG1qHK8jB7H2SaFptn+AY9Q+w93nRQMM+zRo8KqGzA/rWupba1eKidxnEz17bLGTfIig8rPpiiKGXEIW5M06TkTGnoe2mslRsIR+c0AIl0hfFMXV+F+AmExAEfzCFJJC8qyfJSIFmtB4H3o1zOF9nv9wBVCmzga9tOetRuHCc5nk6S/D4SH4qyEqZvLpdUSnxUUMI01JAKm1KUpVxS+CsrFmoclcNiwLcsUz98rCka2gdDRYxMwG73Sb5++QYF6DKIO6ZNP1TlUPjxw06evv9AQKeBOREM9vDwOTw+Pkl9vQWjauBk1DR9kzzLVaFb91rUjcRM9YwS5HmxNAR7IwhZ1zxNQ/GWob5QWCFC1/WayjTNWhsFeBa9VgKSMQh9mx97fDzLgDHShk4zUkbuBJA0qt3qqGPRLyninc3Y/N1C2EAh6o+mWL4HY63dyCIoNoVjERXG/ViOSEhskhzD8/OLZFkWDIvOzSW61yGOIA5tzIBXcAu8Dj6fpfZpYMfhC+bX/qAdtPb6fg4x7IdDIq+vPzEeuXaT3Va166DzkuOKyg1j0yAwr6s5nc46uJzDdgWwTiTGGKiRiN+8/PHdQ6mwEciIdz2ufwB724TduJAg1wAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Race Times wireframes",
          "title": "Race Times wireframes",
          "src": "/static/9bd27a5801acd07c73a48274a025bf00/d9199/wireframe-1.png",
          "srcSet": ["/static/9bd27a5801acd07c73a48274a025bf00/8ff5a/wireframe-1.png 240w", "/static/9bd27a5801acd07c73a48274a025bf00/e85cb/wireframe-1.png 480w", "/static/9bd27a5801acd07c73a48274a025bf00/d9199/wireframe-1.png 960w", "/static/9bd27a5801acd07c73a48274a025bf00/c1b63/wireframe-1.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <Section name="Design System" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Design System</h5>
        <p>After looking through a few wireframe ideas, I took inventory of what components and foundational UI I would need in order to begin a build out. Using Figma, I began documenting the design tokens and used them to start building the components within the design tool.</p>
        <p>I wanted to stick a familiar color palette and create a seamless experience with the Formula 1 brand, so I based the color system from <strong>Formula 1's revamped brand guidelines</strong>. By using <strong>Inter</strong> for typography, I could help ensure legibility throughout the app and have a wide range of typographic control. A huge thanks goes out to all of the awesome contributors to the Inter project.</p>
        <p>My end goal of the design phase of this project was to have Figma design assets, design guidelines, and react & CSS components - helping for a more rapid development process when other features may be added in the future.</p>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAADnklEQVQ4y22Sf0zUdRjHv43a+rOVzY1BYU2gZpIKjEQN2JJhrEXZH3oqogIHRgk20Lu1lhxCYYBYLiv88VdtrTW31E0uU5Pc4qTIO8+JCnHI3eH95L7fu+9978vz7vl8o2Gbz/Z8n8/2eb6vz/M870eqNjXCVLUFtvZe9B8ewJH+4/j8yIl/4xenYLO240DTHly4OASH40+4b96G03XLcHG+cWMMLtct+tD6KXbW7CWpvLQSL2dkYnjYAV3XoWsaVEWGnkwCc3MACMJkRUFMVpCIx+Gbume4ymctyfmaRh22w9ixvYWBr72JzGeeg8/rw3+mBQOITE9Dj4SBVGoeOX/HgEgghMnxCXjvTUPhR6KzMtkO9C0Asxhoa+9AT08Pujq7cNBmw8T4OFeTgMw/EBHf9cJsbkBzSwt2NzXB55+Byl0oShwpXaeDtv4F4JKs55GT/QKefGIRFj21GI89+jgG7T8jEAjC45kyRlFaVgZJkpCWlmbE0dFRBPneO+2FqqrU/nEvarbtIamspBLPZi7B0qU5nPiIkSzczsCEmkQoHEFSS8Fk2oL09HRkZ+cgNzcXbrcbSa4wGo0ikVDJ4RhF8/sfkVSybgMyM7JQs7Uam97eiNa2NlisVkz8PcmtgqEqAzUcHziJsdt3MHM/gCmendfnRzgSxexsTIhFCo/Haukiad2acmQw0PRGFTZXbECch24Yi5FIJECG0oCe0o2o8r1Q28MqB4NhCFCcKxTw/fs6Saoo34gVeYVYvboEy/PyOSEO+09n8OP3P8A9MQlZrM+8zTE8zgAhRDAURohnqHCFCgMj0VlYBLC+thWN5v0ozF+L4qI18HNLpSvz8d6mzVBHroF8XpAc4wpT0BkoBBKqi7lGgiEoHo94hIGxB4EWA/hKUTFm/H68tLIIuyoqga+OQr/0C7S7d/Awk2My4lyAEIV3cQG4u8GC5csKULCqkPdORuGqIlS99Q5O2+2Y4gfEFM+fH8Sli5fx6+UrcF53Y+jKMHo+O4buQ8dwqPtL6v7kKN5ttBhAYiCtLV5Ppa+up9+GfqcVeQVUUf46dXZ0kMvpIm6T+vr66ZuvB+jkiVN09swgffftadq1Yy9t592rqW4WDsEyKqyvbYO5rg0N9ftQu/MDLH46A1tN1Rgbu8t7GH5ou37/fSP/QWcGSeIjyMLF2VzXSstezOcX64xqr14dppFrf9EfI9cNF2en8yadO3fhf/8Zkf0fR3p8pJ2ZbYYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Race Times Design System",
          "title": "Race Times Design System",
          "src": "/static/eaa55c5ce3a3cb26ffc66fd82ea62809/d9199/design-system-1.png",
          "srcSet": ["/static/eaa55c5ce3a3cb26ffc66fd82ea62809/8ff5a/design-system-1.png 240w", "/static/eaa55c5ce3a3cb26ffc66fd82ea62809/e85cb/design-system-1.png 480w", "/static/eaa55c5ce3a3cb26ffc66fd82ea62809/d9199/design-system-1.png 960w", "/static/eaa55c5ce3a3cb26ffc66fd82ea62809/c1b63/design-system-1.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <Section name="Design & Build" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Design & Build</h5>
        <p>Using the wireframes, high-fidelity mockups, and design notes, I set out to create a simple tool using HTML (PUG), SCSS, and Javascript. By connecting to the Ergast Formula 1 API, I could gather realtime data of races and seasons.</p>
        <p>In the early feedback sessions with F1 fans and non-F1 fans, I found that color contrast was causing usability issues for users quickly scanning the race list. To rectify that, I audited the design against <strong>a11y</strong> standards and made background/foreground/text color changes that would meet AA benchmarks. In a subsequent and quick feedback session, the same group of testers found it much easier to scan and differentiate UI elements more easily than in their previous round of feedback.</p>
        <ProjectBox image="race-times-example-icon" title="Race Times" description="Tracking the local times of the race calendar" link="https://grapalab.com/f1-race-times/" mdxType="ProjectBox" />
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAADHklEQVQ4y4WT309TZxjH+QMWE28005gNKWCxlK4UiggKS1hm1GwqM2FEE70lIV6wCQJGI6g3JmaJV84LGGDCQsJ+XRnCRCilXTONJFsi0BaktbS2PafFQrHvx/ecgmFZlz3Jm/c57znnc77P9zlPHpAWQqS1XVuZbfn28/9bW8/msRlvMxk8LhcP+/q42t1Nd2cnD+7f5/fxcVKplH6/78H39F67xq2bN+m9cYMr7e0MDg6yPfIkWchdRKJRMfHokQh6veLJHx7xxOUWgZgiJmdcYtHvF8FQSByrOyrKS0tFUZlF5BcfEDt27RbVtUfEm9VVnaFFXpYHEsjj334l+cxD5q/nZP6eBZm7fx5lKRDAv7hES3MTty+eo/vsabrk6jjxGRe/PElUVXXIP4AraoKJe3dRezsIdV3iVesFlAunmLjcyuJKmBc+P/eav0K53kbqdhdvrrTwtvU83zWdYSkSzQGMxnj8cIDVH38g7p4m/tRD6vmfTP7yk65uXlPYUM+dT6v5oszE8ZJiPjfs51jNYaKK8m9gOBLBOTlJwu/D//IlS+GI3j7P02f4vF6Wg0GO2qs4VVdHbX0DlTV1fFxYQoX9EMlEIgcwHGba6SSV3iAoPUuqqn4+MzODz+cjKIFWqw1zmQ27rYoykwVDfiGHqqpRE7k8XFnB4ZhGiSssLCywvBxgY2MDl9uN15sFlprM2KyV1Ep19spqCvYXUVFhJ5FLoQacmnKgKArz8wuE5HU6ncYpVWcVBjhYYsIslVVWVGEutfDRvnzKper/BDocDpLJVebm5gjIstfW1vSSvdJDTaHReBCLufy9wiKDEesn5RKo5vZwSgLX19N6nkwm9XOnM+uh9oESCTTIMo0HTLLcQnbv2qMD1Vz/YSgUYmxsjNevo8zOzuKVkIwct3E5epoFAVnyng/3UiAbYbXYMBab2PHBTgwFhbmBml+jo6MMDAwwNDTEyMgIw8PD9Pf3ax5J5et80/YtZ0438nVTM42NZzlx/CQ9PT1sjm8WuJnoUE1RLBbTGxOPx4nKcdQ6vT20Z7Zi671Nlr6/A/pAx7YzGkEhAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Race Times Design and Build",
          "title": "Race Times Design and Build",
          "src": "/static/8eb973773c3c7106a5f21e239c5364fe/d9199/preview-2.png",
          "srcSet": ["/static/8eb973773c3c7106a5f21e239c5364fe/8ff5a/preview-2.png 240w", "/static/8eb973773c3c7106a5f21e239c5364fe/e85cb/preview-2.png 480w", "/static/8eb973773c3c7106a5f21e239c5364fe/d9199/preview-2.png 960w", "/static/8eb973773c3c7106a5f21e239c5364fe/c1b63/preview-2.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <Section name="Feature: Selection" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Selecting Seasons</h5>
        <p>One of the early feedback sessions found that users might want to:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <ul>
            <li>See if a track from a previous season was in the current season (or vice versa)</li>
            <li>Compare the number of races this season versus the number from a previous season</li>
          </ul>
        </Box>
        <p>To give users the ability to accomplish these needs, I implemented a season selection that would let users select a season from the past 20 years and view their currently selected season. Users are able to select a season from the dropdown - thereby re-generating the list of races.</p>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABCcAAAQnAEmzTo0AAACIUlEQVQoz4WTS2/TQBSF8+eAVqp4CGgq6EuoVNBWpVCBQGVBhRDisQCxgi1rdvwBFlQgAa2KkjiJk7hpnDiOm4cfM3amzOHOuEEFqWLx6VxfX5+5M3ec4XECzmPw4SG4+IU4GSIZCq1xIsDoHaMaFsca/i88Vca5jjNMJxJEngfWasH3A3R7ffT6KWlx+sGJsNRMaUavQN2Eu9tgW5/QcRxUajVY1h5qloV4tAM+Mv4b1YzdbNLiAx3rDpVzP2ToRhwD6jAIQkSUU6jn/9HxDtAf+EeGLEZEpm3HRaO+T6u10LCbGFCBf2R+Er7SMESn4+mYjc4wYgztgy7Rw4C6CngCn/LHCRRsBNf4IyKmPfQZKsOQtlrZ2Yb5/RsC29bDiVpNhE07xW4gIPUpd5xA1ThU67qIokgfUYapgdCE/Y8fMHj/Dp3XT+G9fQXnzTPUnz9C/cUmrJePUXp4G8Wb0yivzKO8PAdrbQH19UU495bgbt6F39hHRNcsQ3dMBp4n955syOqdRWneX5Hmxpo0H6xK49aCzK3fkLnVa9KYuyCN7LjMT47JQnZMlq9MyBJRpLg0f1F2K6Zk4lAqQwQ0cnN5FoXLp1FcmoFBnYwoXJ9CcfoszJlzKF+dQGlqHMXsOIzJM6leOgVj9jx6tSoY/RgZ2rcIIya8akW087k/uEoLOeH83BXO1y3hfPksnJ0fwjUKOq9rCnmtnVJRBH4glNdviqQPEowj/PQAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Selecting seasons",
          "title": "Selecting seasons",
          "src": "/static/221161211b2192265620ccb23443033a/d9199/seasons-1.png",
          "srcSet": ["/static/221161211b2192265620ccb23443033a/8ff5a/seasons-1.png 240w", "/static/221161211b2192265620ccb23443033a/e85cb/seasons-1.png 480w", "/static/221161211b2192265620ccb23443033a/d9199/seasons-1.png 960w", "/static/221161211b2192265620ccb23443033a/e996b/seasons-1.png 1050w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <Section name="Feature: Dark Mode" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Dark Mode</h5>
        <p>Let's face it — some F1 races are early 😴. Viewing a very bright UI in a dark environment might be hard on the eyes, so I thought to implement a dark mode that can be toggled. Also, I'm a sucker for dark modes 🤩 (in case you didn't guess from the dark mode in this portfolio).</p>
        <p>By using mapped design tokens, in the form of some modern <code>css</code> (CSS custom properties), users may be able to toggle between light and dark modes.</p>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABCcAAAQnAEmzTo0AAACk0lEQVQoz12RS08TYRSGRy4hlg4z03Z6o7fpZXqhd0rpfSEGELmugABVE0kQ0ZhIIguVlUriwsS49F+4c2PiziAYFoSABTTG6MJ/MMn3emaSEuPiyTkzc57vPV+GazQaqNfrKFWrKFYqKJXLGC4WUSSGhoYuaqFQwODgIPL5PHK5nEE2m0Umk0EymUQqlTIqp8sFEq6USxgvD6NcKqFaq6FMB+voQjqdvhAGBgaQSCQMYrGYQTqVRjQaRSQSAZfNUgIJK5UiNmpFNCpljI6NYWRkxCAaVY1hVVUNIRwOIxQKIRgMQlEUBJUg8rkC9UF4vV5w7RSVEkPxhCHrUlv4/4BAIAC/3w+fz2fgdrsNXC4X7HY7uPZQIh5HRr8WXUnHEEnwejzw9Pej/x/R5XTC6XBAlmXaUIFKm9usNkiSBK6dEKAtfLSFTMMW2W4gkSDZZIg2G0QSRIsVgsVygblPgC+gwEPBPM9DFEVwVqsVNhJH6adMVMtI+ryIu12IEVGX00B12hFxOhCmvk3IeHYg6JCh2ClUEGA2m8Hx1PhkG9425/Fx8y7+vN7Brzcv8fPVM5w9f4yzF0/Q2tnG0dY9HK4u4ujOMo7WltDauInT+7fw+9E6jh/cpmAHunp6wPXyPHNZJPZuYZodrjfZj+1Ndk58e/qQtTbX2MnWBjuhero6z06Xp9nXpSnWIs5vzLGz5gz73pxlBwsTLGKV2KXubsZdNpkg0qrvr9VwPD+O3eUZ7K3MEbP4TOwuTuLTZB27kw3sTzXwhfr96zXsTVSxR/WA+HC1CD9P1+3oAGcymTQdRRI01SJoEalPi1pELWaV6JkqvR+WJa1EJOl7WJ8jwgKvhQQzVbMW4Hu1nu4urbOzU/sL1xBq1Pb1IYcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Toggle Dark Mode",
          "title": "Toggle Dark Mode",
          "src": "/static/d32c74f401f50beb8d10e5c756220caf/d9199/dark-mode-1.png",
          "srcSet": ["/static/d32c74f401f50beb8d10e5c756220caf/8ff5a/dark-mode-1.png 240w", "/static/d32c74f401f50beb8d10e5c756220caf/e85cb/dark-mode-1.png 480w", "/static/d32c74f401f50beb8d10e5c756220caf/d9199/dark-mode-1.png 960w", "/static/d32c74f401f50beb8d10e5c756220caf/e996b/dark-mode-1.png 1050w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <Section name="Feature: Filtering" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Filtering Races</h5>
        <p>Sometimes, users may want to see races in relation to each other. By the law of common region, we can define three categories for races:</p>
        <CSSGrid mdxType="CSSGrid">
          <Col col={1 / 3} media={{
                  phone: 1
                }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center bold color={({
                        theme
                      }) => theme.green} mdxType="Text">Upcoming Races</Text>
              </CardBody>
            </CardContainer>
          </Col>
          <Col col={1 / 3} media={{
                  phone: 1
                }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center bold color={({
                        theme
                      }) => theme.green} mdxType="Text">Past Races</Text>
              </CardBody>
            </CardContainer>
          </Col>
          <Col col={1 / 3} media={{
                  phone: 1
                }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center bold color={({
                        theme
                      }) => theme.green} mdxType="Text">All Races</Text>
              </CardBody>
            </CardContainer>
          </Col>
        </CSSGrid>
        <p>To help users categorize races in familiar patterns, I implemented a tab bar to categorize a season's races. Users may use the tab bar to quickly filter between upcoming and past races in the currently selected season. If they wish to view the full season calendar, past races will be visibly faded in order to differentiate between past and upcoming races.</p>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABCcAAAQnAEmzTo0AAAB9ElEQVQoz12S267SYBCFm0A4H8oZypmWFhMObja0HFoKNdGgMZqYGBLj1X4EX8Arn8M7H5Kky5nZlmy5+DL9p2tmTeevcjgc4HkeXNeFu9/D3mzx9DbAry8f8fPze3xw99h7Po7+EY6zwXazI60Hx3awWCyE+XyO2WyG6XQKxbZtrNfrZ1YrzF4/4Me7AH+evuH396/4tLWxdX0ExzdYLh9hrx14dH5crjCZTATLsmCaJsbjMZTIgZn+c9Enr9DVDXQIg8SW9SxmdF3HcDj8j8FggH6/j16vB+Wlg2mOYRhUaBiCwcUk1tpttFottCl2u12BnzVNk3yz2USj0RCUeweGCzqdDok1WoWNIAhwPp+xfFih3xugVqsJ1WoVlUpFKJfLgnLvUq/XhahoNNJxuVxwOp1gji3SNKmwJMWlUgmqqt4oFotQ7l1YlM/nkc1mUa810GxosjfOJ5NJZDJpIoN0Oi26QqEgkcnlclBeOvBLbsz7HI1GcgkG7ZLXEZ15PdFlsAk3Z3OOjEJNwgh6EdLE4W63C33fF+g/Dek/lZxtOwLnOUdrChOJREjThqlUKqQvCJWoc/QZPHY+X5CYyWRpLyrdZhttQlVLMg0VE2mJ8Xj8RiwWg0KF1wgSX6nxDTKQyHmGzzTFlRpJTCYTEmnKG38BErUrWAhL4/wAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Empty State when there is no data",
          "title": "Empty State when there is no data",
          "src": "/static/7dbd5ffe939d5fd3d7b71f22a355b6ad/d9199/empty-state-1.png",
          "srcSet": ["/static/7dbd5ffe939d5fd3d7b71f22a355b6ad/8ff5a/empty-state-1.png 240w", "/static/7dbd5ffe939d5fd3d7b71f22a355b6ad/e85cb/empty-state-1.png 480w", "/static/7dbd5ffe939d5fd3d7b71f22a355b6ad/d9199/empty-state-1.png 960w", "/static/7dbd5ffe939d5fd3d7b71f22a355b6ad/e996b/empty-state-1.png 1050w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <Section name="Feature: Empty States" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>Empty States</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <Text italic mdxType="Text">What if a season doesn't have any upcoming or past races?</Text>
          </CardBody>
        </CardContainer>
        <p>We needed to design an empty state to inform users if there are no races in their selected category. For example, if a user has selected a past season, there would be no upcoming races in that season - thereby rendering a empty state.</p>
        <p>If there are no past or upcoming races when a user selects the respective tab filter, an empty state eill be generated - prompting the user to change their selection to an option that may contain filled data.</p>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAACTklEQVQ4y32Ty2/aQBDG/ef20lOlRqrUSIEeUkVRQloQidq0l+TEIyQBzKOHVlWk9tgUIqG62OFhDIoxYLANlK87ixZtLLVIo/Wa8W+/mW9WmbhTiBhPXB4zz0dTN5C7zuNtPIFoNIqdnQgOYzGkMxlo2m+eQ7niu6Ez4qsiICJG4wnmiyXK1Sq079/wpVTC8ftTnH74iK83N6jV75DLXWKx/MNzZTHOaLwGyi8pyQ/mqFY/QavXEUwmwGoF8et2Ori8uobnB7CHDocIMcRQ5FI3wPkC5WIRv97F4cT3MHyzC3s/gvGrLWiRLaYwh4BVQTDKp1WEIm+oD2uFC6iqCm0vgofX27B2X8J68RQPz5+gsf0MmUyWA+WShbBND93pbAOmktVKBffqFbzPFUxHI8xcFwEzwmBmZS8ueJ8JGPZAkelCYcBKLpXLMA0dPV2Hbhi4b7XYoVP0BwMOFKbI/eM9FO4ImACqagmm1YftODBNE57ncVPMrolMNvtvlwVI/CGARdbDdruNbreLZrPJw2Fw2lMPCSj3XxikhIdazGGxqHJltm2j1+vB932ukJ5T6TTvM42NKPtRyfLE00k0Y/l8gSnsMEUmGo0Gvx2WZaHFVBNwHnJ5Y0pYNp1Kp+cLBa5wyPYdNsykcMUGnEomYNiURy7LKsVNWQN76PcHMJjLOhsXj0FJZSqV/r/LImSF5GStVuMwWkmZy2axzq7j2dk5luw2imsnDzd3WXZJKPxZqyN2dIR4IoFkMonjkxMk2PPBwSF+3N7ynLAhFH8BEYRLF9MD0mkAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Race Times Final App",
          "title": "Race Times Final App",
          "src": "/static/3483f5acbd1c95f621e74cacbe72f067/d9199/preview-1.png",
          "srcSet": ["/static/3483f5acbd1c95f621e74cacbe72f067/8ff5a/preview-1.png 240w", "/static/3483f5acbd1c95f621e74cacbe72f067/e85cb/preview-1.png 480w", "/static/3483f5acbd1c95f621e74cacbe72f067/d9199/preview-1.png 960w", "/static/3483f5acbd1c95f621e74cacbe72f067/c1b63/preview-1.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <Section name="Next" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>💡 Takeaways and Next Steps</h5>
        <Box bg={({
                theme
              }) => theme.grey200} color={({
                theme
              }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                  marginBottom: '0'
                }}><strong>Research should inform the design:</strong> When starting this project, I tended to make design assumptions and stuck to them — leading to incomplete design outcomes. Greater success came when I learned to reiterate the design goals and test patterns with users more granularly, rather than large sweeping changes.</p>
        </Box>
        <Box bg={({
                theme
              }) => theme.grey200} color={({
                theme
              }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                  marginBottom: '0'
                }}><strong>Empathize more:</strong> Even though user feedback was collected, it is always helpful to spend more time understanding what the user needs.</p>
        </Box>
        <Box bg={({
                theme
              }) => theme.grey200} color={({
                theme
              }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                  marginBottom: '0'
                }}><strong>Sharing and research:</strong> Even if the number of research participants was small (~9), it gave me a a greater understanding of how some F1 fans (and even non-F1 fans) might use this feature. In the future, I should think about a reaserch framework to help rapidly test new features as well as find a way to test among a greater number of participants. <i>Forums? Reddit groups?</i></p>
        </Box>
        <Box bg={({
                theme
              }) => theme.grey200} color={({
                theme
              }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                  marginBottom: '0'
                }}><strong>React, like the cool kids:</strong> Even though this project is fully functional in live code, leveraging a modern tool, like React, will help make this application simple to build upon by being incredibly component-friendly.</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      